<template>
  <div>
    <h1 class="text-2xl mb-6">
      Performance audience sur applications de messagerie
    </h1>
    <v-card class="mb-6 pa-2">
      <v-row class="ma-3">
        <v-col cols="12">
          <filters></filters>
        </v-col>
      </v-row>
    </v-card>
    <loader v-if="isLoading"></loader>
    <topStatsCard></topStatsCard>
    <linkedKpiTable></linkedKpiTable>
  </div>
</template>
<script>
import filters from "@/components/link-mess-apps/filters/Filters";
import linkedKpiTable from "@/components/link-mess-apps/tables/linkedKpiTable.vue";
import topStatsCard from "@/components/link-mess-apps/cards/TopStatsCard.vue";
import Loader from "@/components/common/Loader.vue";
import useAxios from "@/hooks/useAxios";

export default {
  name: "LinkMessagingApp",
  components: {
    filters,
    linkedKpiTable,
    topStatsCard,
    Loader,
  },
  setup() {
    const { isLoading } = useAxios();

    return {
      isLoading,
    };
  },
};
</script>
<style></style>
