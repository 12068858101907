<template>
  <div>
    <v-card class="pt-7 pb-3 mb-3">
      <v-row>
        <v-card-title>
          <h2 class="text-xl mb-6">
            Principaux indicateurs des articles suivis
          </h2>
        </v-card-title>
        <dot-menu
          @exportCsv="exportCSV"
          :position="'page-top-right'"
          :csvButton="{
            csvData,
            filename: `link-mess-apps-${this.startDate}_${this.endDate}.csv`,
          }"
        ></dot-menu>
      </v-row>

      <v-row>
        <v-col cols="8">
          <v-text-field
            class="ml-6 mr-6"
            v-model="search"
            append-icon="mdi-magnify"
            label="Recherche (par Site, Titre)"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-select
            class="ml-6 mr-6"
            outlined
            clearable
            v-model="search_column"
            :items="['site', 'title']"
            :menu-props="{ maxHeight: '200', zIndex: 13 }"
            label="Dans la colonne"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-data-table
          class="ml-6 mr-6"
          :items="postItems"
          :headers="postsHeaders"
          :options.sync="options"
          :sort-by="options.sortBy"
          :sort-desc="options.sortDesc"
          :server-items-length="options.total"
          :page="options.page"
          :pageCount="numberOfPages"
        >
          <template
            v-for="header in postsHeaders"
            v-slot:[`header.${header.value}`]="{ header }"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">{{ header.text }}</span>
              </template>
              <span>{{ header.tooltip }}</span>
            </v-tooltip>
          </template>
          <template v-slot:item.title="{ item }">
            <div class="truncateCell" :title="item.title">
              <a :href="item.url" target="_blank">{{ item.title }}</a>
            </div>
          </template>
          <template v-slot:item.created="{ item }">
            <div class="truncateCell" :title="item.created">
              {{ formatDate(item.created) }}
            </div>
          </template>
          <template
            v-for="key in [
              ...slotAudienceKeysToFormat,
              ...slotCurrencyKeysToFormat,
            ]"
            v-slot:[`item.${key}`]="{ item }"
          >
            <span style="white-space: nowrap">
              {{ prettyNbs(item[key])
              }}<span v-if="slotCurrencyKeysToFormat.includes(key)"> €</span>
            </span>
          </template>
        </v-data-table>
      </v-row>
      <v-row>
        <v-card-title>
          <h3 class="text-xl mb-6">Regroupement par sites</h3>
        </v-card-title>
      </v-row>
      <v-row>
        <v-data-table
          class="ml-6 mr-6"
          :items="postGroupedItems"
          :headers="postsGroupHeaders"
          :options.sync="options"
          :sort-by="options.sortBy"
          :sort-desc="options.sortDesc"
        >
        </v-data-table>
      </v-row>
    </v-card>
  </div>
</template>

<style scoped>
.truncateCell {
  max-height: 60px;
  max-width: 200px;
  overflow: hidden;
}
</style>

<script>
import DotMenu from "@/components/common/menus/DotMenu";
import { formatPercent, prettyNbs } from "@/utils/formatting";
import { paramsSerializer } from "@/utils/paramsSerializer";
import axios from "@/plugins/axios";
import debounce from "lodash/debounce";
import useAxios from "@/hooks/useAxios";
export default {
  name: "LinkedKpiTable",
  components: {
    DotMenu,
  },

  data() {
    return {
      optionsInitialized: false,
      forceCall: false,
      csvData: "",
      slotAudienceKeysToFormat: ["total_sessions", "total_screen_page_views"],
      slotCurrencyKeysToFormat: ["total_estimated_revenue", "average_rpm"],
      postItems: [],
      postGroupedItems: [],
      postsGroupHeaders: [
        { text: "site", value: "site_name", sortable: false },
        { text: "Nombre de liens", value: "total_articles", sortable: false },
        { text: "sessions", value: "total_sessions", sortable: false },
        {
          text: "screen_page_views",
          value: "total_screen_page_views",
          sortable: false,
        },
        {
          text: "rpm",
          value: "average_rpm",
          sortable: false,
        },
        {
          text: "CA Estimé",
          value: "total_estimated_revenue",
          sortable: false,
        },
      ],
      postsHeaders: [
        {
          text: "Titre de l'article",
          value: "title",
          sortable: false,
          tooltip: "Lien cliquable vers l'url de l'article",
        },
        {
          text: "site",
          value: "site_name",
          sortable: true,
          tooltip: "Nom du site",
        },
        {
          text: "date de création",
          value: "created",
          sortable: true,
          tooltip: "Date de création de l'article",
        },
        {
          text: "sessions",
          value: "total_sessions",
          sortable: true,
          tooltip: "Nombre de sessions",
        },
        {
          text: "screen_page_views",
          value: "total_screen_page_views",
          sortable: true,
          tooltip: "Nombre de pages vues",
        },
        {
          text: "rpm",
          value: "average_rpm",
          sortable: true,
          tooltip: "RPM moyen du site sur la période",
        },
        {
          text: "CA Estimé",
          value: "total_estimated_revenue",
          sortable: true,
          tooltip: "sessions * RPM moyen sur la période",
        },
      ],

      options: {
        page: 1,
        itemsPerPage: 10,
        total: 10,
        sortBy: [],
        sortDesc: [],
      },
      numberOfPages: 0,
      search: "",
      search_column: "",
      isLoading: false,
    };
  },
  setup() {
    const { axiosGet } = useAxios();

    return {
      axiosGet,
    };
  },
  methods: {
    prettyNbs,
    formatPercent,
    formatDate(date) {
      return date.split("T")[0];
    },
    debouncedUpdateData: debounce(async function () {
      await this.getData();
    }, 500),
    async getData() {
      if (
        (this.filtersApplied === false && this.forceCall === false) ||
        this.isLoading ||
        !this.startDate ||
        this.startDate.length !== 10 ||
        !this.endDate ||
        this.endDate.length !== 10
      ) {
        this.forceCall = false;
        return;
      }
      this.isLoading = true;
      // this.$emit("update:isLoading", this.isLoading);
      const params = {
        search_by: this.periodType,
        sites_id: this.sites,
        date_start: this.startDate,
        date_end: this.endDate,
        page: this.options.page,
        limit: this.options.itemsPerPage,
        search: this.search,
        search_column: this.search_column,
        sort_by: this.options.sortBy,
        sort_desc: this.options.sortDesc,
        group_by_site: false,
      };

      const paramsSitegroup = {
        search_by: this.periodType,
        sites_id: this.sites,
        date_start: this.startDate,
        date_end: this.endDate,
        page: 1,
        limit: 0,
        search: this.search,
        search_column: this.search_column,
        sort_by: this.options.sortBy,
        sort_desc: this.options.sortDesc,
        group_by_site: true,
      };

      const response = await this.axiosGet("link_messapps/kpis", params, {
        paramsSerializer,
      });

      const responseSitegroup = await this.axiosGet(
        "link_messapps/kpis",
        paramsSitegroup,
        { paramsSerializer }
      );

      this.postGroupedItems = responseSitegroup.data.data;

      this.options.total = response.data.total;
      this.postItems = response.data.data;
      this.numberOfPages = Math.ceil(
        response.data.total / this.options.itemsPerPage
      );

      // this.$store.dispatch("linkMessApps/updateKpi", response.data.data); --> if need to send to another component
      await this.$store.dispatch(
        "linkMessApps/updateGlobalStats",
        response.data.stats
      );
      this.isLoading = false;
      // this.$emit("update:isLoading", this.isLoading);
      await this.$store.dispatch("common/updateApplyFilters", false);
      this.forceCall = false;
    },

    async exportCSV() {
      const params = {
        search_by: this.periodType,
        sites_id: this.sites,
        date_start: this.startDate,
        date_end: this.endDate,
      };

      const response = await axios.get("link_messapps/csv_kpis", {
        params,
        paramsSerializer: paramsSerializer,
      });

      this.csvData = response.data;
    },
  },
  created() {
    // this.debouncedUpdateData();
  },
  computed: {
    sites() {
      return this.$store.getters["linkMessApps/getSites"];
    },
    startDate() {
      return this.$store.getters["linkMessApps/getDates"][0];
    },
    endDate() {
      return this.$store.getters["linkMessApps/getDates"][1];
    },
    periodType() {
      return this.$store.getters["linkMessApps/getPeriodType"];
    },
    filtersApplied() {
      return this.$store.getters["common/getApplyFilters"];
    },
  },
  watch: {
    filtersApplied() {
      this.getData();
    },
    options() {
      if (this.optionsInitialized) {
        // Not on initialization : wait for a real user change
        this.forceCall = true;
      }
      this.optionsInitialized = true;
      this.getData();
    },
    search() {
      this.forceCall = true;
      this.debouncedUpdateData();
    },
    search_column() {
      this.forceCall = true;
      this.getData();
    },
  },
};
</script>
